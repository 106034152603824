<template>
	<div
		class="full-height flex-column"
	>
		<div class="">
			<h6 class="text-left">소속 영업점</h6>
			<div class="top-line-identify pa-10 text-left box bg-eee">
				<template

					v-for="(agency_type, index) in codes.upAgencyList"
				>
					<select
						v-if="agency_type.use"
						v-model="agency[agency_type.key]"
						:key="'agency_type_' + index"
						class="input-box-inline mr-10 bg-white"
						@change="getAgencyList($event, agency_type.key)"
						:ref="agency_type.key"
					>
						<option :value="''">{{ agency_type.name }}</option>
						<option
							v-for="(agency, sub_index) in agency_list[agency_type.key]"
							:key="'agency_' + sub_index"
							:value="agency.idx"
						>{{ agency.name }}</option>
					</select>
				</template>
			</div>
		</div>
		<div>
			<h6 class="text-left">매출 합계</h6>
			<table class="table top-line-identify">
				<thead>
				<tr>
					<th>매출 시작일</th>
					<th>매출 종료일</th>
					<th>정산 기준일</th>
					<th>승인금액</th>
					<th>정산금액</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>{{ item.fromDate }}</td>
					<td>{{ item.toDate }}</td>
					<td>{{ item.calDate }}</td>
					<td>{{ summary.totalApprovalAmount | makeComma }}</td>
					<td>{{ summary.totalCalAmount | makeComma }}</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="mt-10 full-height flex-column">
			<h6 class="justify-space-between">
				매출 내역

				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel2"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">금일 대량 이체파일</span></button>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10  size-px-12"
						v-model="search.size"
						@change="getData()"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</h6>
			<table
				v-if="items.length > 0"
				class="table top-line-identify"
			>
				<thead>
				<tr>
					<th>NO</th>
					<th>승인일시</th>
					<th>정산 기준일</th>
					<th>서비스 타입</th>
					<th>가맹점</th>

					<th>승인번호</th>
					<th>할부</th>
					<th>수수료율</th>
					<th>승인금액</th>
					<th>정산금액</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'item_' + index"
				>
					<td>{{ item.idx }}</td>
					<td>{{ item.approvalDate}}</td>
					<td>{{ item.calDate }}</td>
					<td>{{ item.type }}</td>
					<td>{{ item.shopName }}</td>

					<td>{{ item.approvalCode }}</td>
					<td>{{ item.installment }}</td>
					<td>{{ item.fee }}</td>
					<td class="text-right">{{ item.approvalAmount | makeComma }}</td>
					<td class="text-right">{{ item.calAmount | makeComma}}</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10 pa-10"
			>No Data</div>

			<pagination
				class="mt-auto"
				:options="search"

				@click="getSearch"
			></pagination>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@finish="is_excel = !is_excel"
		></Excel>

		<Excel
			v-if="is_excel2"
			:excel_data="excel_data2"
			:date="date"

			@finish="is_excel2 = !is_excel2"
		></Excel>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { codes } from '@/resources/config/codes'
import { Axios } from '@/resources/axios/axios'
import {date} from "@/resources/date/date";
import Excel from "@/components/Excel";

export default {
	name: 'SettlementDetail'
	,components: {Excel, Pagination}
	,props: ['item', 'user']
	,data: function(){
		return {
			codes: codes
			,Axios: Axios
			,date: date
			,agency: {
				branchList: ''
				, distributorList: ''
				, agencyList: ''
				, resellerList: ''
			}
			,search: {
				page: 1
				,size: 10
				,branchIdx: ''
				,distributorIdx: ''
				,agencyIdx: ''
				,resellerIdx: ''
				,type: ''
				,total_count: 0
			}
			,summary: []
			,items: []
			,agency_list: {
				branchList: []
				, distributorList: []
				, agencyList: []
				, resellerList: []
			}
			,is_excel: false
			,excel_data: {
				name: '가맹점 정산 상세'
				,header: [
					{ key: 0, name: '승인일시', column: 'approvalDate'}
					,{ key: 0, name: '정산일시', column: 'calDate'}
					,{ key: 0, name: '서비스 타입', column: 'type'}
					,{ key: 0, name: '정산주기', column: 'calCycle'}
					,{ key: 0, name: '가맹점', column: 'shopName'}
					,{ key: 0, name: '카드사', column: 'cardCompany'}
					,{ key: 0, name: '카드번호', column: 'cardNum'}
					,{ key: 0, name: '승인번호', column: 'approvalCode'}
					,{ key: 0, name: '할부', column: 'installment'}
					,{ key: 0, name: '승인금액', column: 'approvalAmount'}
					,{ key: 0, name: '수수료율', column: 'fee'}
					,{ key: 0, name: '수수료', column: 'feeAmount'}
					,{ key: 0, name: '부가세', column: 'vatAmount'}
					,{ key: 0, name: '정산지급금', column: 'calAmount'}
					,{ key: 0, name: '정산상태', column: 'state'}
				]
				,content: null
			}
			,is_excel2: false
			,excel_data2: {
				name: '가맹점 정산 대량 이체파일'
				,header: [
					{ key: 0, name: '은행코드', column: 'code'}
					,{ key: 0, name: '', column: 'wDate', text: ''}
					,{ key: 0, name: '계좌번호', column: 'account'}
					,{ key: 0, name: '금액', column: 'amount'}
					,{ key: 0, name: '상호', column: 'shopName'}
					,{ key: 0, name: '예금주명', column: 'depositor'}
					,{ key: 0, name: '', column: ''}
					,{ key: 0, name: '', column: 'name', text: ''}
					,{ key: 0, name: '', column: 'name2', text: ''}
					,{ key: 0, name: '', column: 'rDate', text: ''}
					,{ key: 0, name: '', column: 'status_name', text: ''}
					,{ key: 0, name: '비고', column: '', text: 'PG대금'}
				]
				,content: null
			}
		}
	}
	,computed: {
		item_list: function(){
			return this.items.filter(function(item){
				return item
			})
		}
	}
	,methods: {

		getData: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'calculate/shop/' + this.item.idx
					,data: this.search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.search.total_count = result.data.tableList.totalCount
					this.summary = result.data.statistics
					this.summary.sDate = this.search.sDate
					this.summary.eDate = this.search.eDate
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getAgencyList: async function(e, type){
			let upperIdx = ''
			if(e){
				upperIdx = e.target.value
			}

			try{
				//this.$emit('onLoading')
				let result = await this.Axios({
					method: 'get'
					, url: 'common/upper'
					, data: {
						upperIdx: upperIdx
					}
				})
				if(result.success){
					this.next_list = result.data

					if(type == 'branchList'){
						this.agency_list.distributorList = this.next_list.distributorList
						this.agency_list.agencyList = this.next_list.agencyList
						this.agency_list.resellerList = this.next_list.resellerList
						this.agency.distributorList = ''
						this.agency.agencyList = ''
						this.agency.resellerList = ''
						this.setAgency()
					}else if(type == 'distributorList') {
						this.agency_list.agencyList = this.next_list.agencyList
						this.agency_list.resellerList = this.next_list.resellerList
						this.agency.agencyList = ''
						this.agency.resellerList = ''
						this.setAgency()
					}else if(type == 'agencyList'){
						this.agency_list.resellerList = this.next_list.resellerList
						this.agency.resellerList = ''
						this.setAgency()
					}else if(type == 'resellerList'){
						//
						this.setAgency()
					}else{
						this.agency_list = this.next_list
					}
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				//this.$emit('offLoading')
			}
		}

		,setAgency: function(){
			this.search.branchIdx = this.agency.branchList
			this.search.distributorIdx = this.agency.distributorList
			this.search.agencyIdx = this.agency.agencyList
			this.search.resellerIdx = this.agency.resellerList
			this.getData()
		}
		,toExcel: async function(){

			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'calculate/shop/excel/' + this.item.idx
					,data: {
						page: this.search.page
						,size: this.search.size
						,startDate: this.item.calDate
						,endDate: this.item.calDate
						,state: ''
						,branchIdx: this.user.branchIdx
						,distributorIdx: this.user.distributorIdx
						,agencyIdx: this.user.agencyIdx
						,resellerIdx: this.user.resellerIdx
					}
				})

				if(result.success){
					this.excel_data.content = result.data
					this.is_excel = true
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,toExcel2: async  function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'quick/calculate/bulk/excel'
					,data: {
						page: this.search.page
						,size: this.search.size
						,startDate: this.item.calDate
						,endDate: this.item.calDate
						,state: ''
					}
				})

				if(result.success){
					this.excel_data2.content = result.data.data
					this.is_excel2 = true
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}
	}
	,created() {
		this.getAgencyList()
		this.getData()
	}
}
</script>